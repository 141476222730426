import React, { useRef, useState } from 'react';
import { Button } from '../Button';

type Props = {
  onChange: (volume: number) => void;
};

export const AudioVolume: React.FC<Props> = ({ onChange }) => {
  const total = 1;
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  const updatePosition = (clientX: number) => {
    const target = elementRef.current as HTMLElement;
    const x = clientX - target.getBoundingClientRect().x;
    const w = target.clientWidth;
    const newVolume = Math.max(total * (x / w), 0);

    setVolume(newVolume);
    onChange(newVolume);
  };

  const onMouseMove = (event: MouseEvent) => {
    updatePosition(event.clientX);
  };

  const onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    setMuted(false);
    elementRef.current?.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    updatePosition(event.clientX);
  };

  const onMouseUp = () => {
    elementRef.current?.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  const onClickToggle = () => {
    const isMuted = !muted;
    setMuted(isMuted);
    onChange(isMuted ? 0 : volume);
  };

  return (
    <div className="grid grid-cols-[40px_1fr] items-center gap-4">
      <Button onClick={onClickToggle} variant="transparent" icon={!muted && volume > 0 ? 'volume' : 'mute'} />

      <div ref={elementRef} className="cursor-pointer py-4" onMouseDown={onMouseDown}>
        <div className="relative h-1 w-full rounded bg-blue-200/10">
          <div className="pointer-events-none absolute left-0 top-0 h-1 rounded bg-blue-200" style={{ width: `${((muted ? 0 : volume) / total) * 100}%` }}>
            <span className="absolute right-0 top-1/2 size-4 -translate-y-1/2 translate-x-1/2 rounded-full border border-white/50 bg-blue-200 shadow-lg" />
          </div>
        </div>
      </div>
    </div>
  );
};
