export function secondsToMMSS(seconds: number): string {
  const totalSeconds = Math.round(seconds);
  const minutes = Math.floor(totalSeconds / 60);
  const remainingSeconds = totalSeconds % 60;

  const minutesStr = String(minutes).padStart(2, '0');
  const secondsStr = String(remainingSeconds).padStart(2, '0');
  return `${minutesStr}:${secondsStr}`;
}

export function secondsToSSMMM(seconds: number): string {
  const totalMilliseconds = Math.round(seconds * 1000);
  const secondsPart = Math.floor(totalMilliseconds / 1000);
  const millisecondsPart = Math.floor((totalMilliseconds % 1000) / 10);

  const secondsStr = String(secondsPart).padStart(2, '0');
  const millisecondsStr = String(millisecondsPart).padStart(2, '0');
  return `00:${secondsStr}:${millisecondsStr}`;
}

export function secondsToMMSSMS(seconds: number) {
  const totalMilliseconds = Math.round(seconds * 1000);
  const minutes = Math.floor(totalMilliseconds / 60000); // 60 seconds * 1000 ms
  const secondsPart = Math.floor((totalMilliseconds % 60000) / 1000);
  const millisecondsPart = totalMilliseconds % 1000;

  const minutesStr = String(minutes).padStart(2, '0');
  const secondsStr = String(secondsPart).padStart(2, '0');
  // Convert milliseconds to a two-digit representation (hundredths of a second)
  const millisecondsStr = String(Math.floor(millisecondsPart / 10)).padStart(2, '0');

  return {
    minutes: minutesStr,
    seconds: secondsStr,
    milliseconds: millisecondsStr,
  };
}
