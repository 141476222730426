import { useFloatingUIStore } from '@/packages/ui/floatingUI.store';
import { Button } from './Button';

export type AlertType = {
  title?: string;
  content: JSX.Element | JSX.Element[] | string;
  buttonLabel?: string;
};

export const Alert = () => {
  const { alert, setAlert } = useFloatingUIStore();

  if (alert) {
    return (
      <div className="fixed inset-0 z-20 flex items-center justify-center">
        <div className="absolute inset-0 bg-black/50" onClick={() => setAlert(null)}></div>

        <div className="relative grid w-full max-w-[500px] rounded-2xl bg-white">
          <div className="flex justify-between p-6">
            <h3 className="text-center text-22 font-bold">{alert.title || 'Alert'}</h3>
          </div>
          <hr />

          <div className="grid p-6">{alert.content}</div>

          <div className="grid p-6 pt-0">
            <Button onClick={() => setAlert(null)} label={alert.buttonLabel || 'Close'} />
          </div>
        </div>
      </div>
    );
  }

  return null;
};
