import { useFloatingUIStore } from '@/packages/ui/floatingUI.store';
import { Button } from '../Button';
import React, { useEffect, useRef, useState } from 'react';
import { Audio, AudioHandle, OnUpdateParams } from './Audio';
import { AudioBar } from './AudioBar';
import { AudioVolume } from './AudioVolume';
import api from '@/packages/api/api';

export type AudioPlayerType = {
  name: string;
  description: string;
  assetId?: string;
};

export const AudioPlayer = () => {
  const { audioPlayer, setAudioPlayer } = useFloatingUIStore();
  const audioRef = useRef<AudioHandle>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [endCheck, setEndCheck] = useState(false);
  const url = audioPlayer?.assetId ? api.media.stream(audioPlayer.assetId) : '';

  useEffect(() => {
    let timer: NodeJS.Timeout | null;

    function updateTime() {
      const audio = audioRef.current;
      if (!audio) return;
      setCurrentTime(audio.currentTime());
    }

    if (playing) {
      timer = setInterval(updateTime, 30);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };
  }, [playing, setCurrentTime]);

  // just close if the user is not listening to the audio
  useEffect(() => {
    let timeout: NodeJS.Timeout | null;

    if (endCheck) {
      timeout = setTimeout(() => {
        if (!audioRef.current?.isPlaying()) {
          setEndCheck(false);
          setAudioPlayer(null);
        }
      }, 5000);

      return () => {
        if (timeout) clearTimeout(timeout);
        timeout = null;
      };
    }
  }, [endCheck, setAudioPlayer]);

  const onUpdate = ({ playing }: OnUpdateParams) => {
    setPlaying(playing);
  };

  const onAudioEnded = () => {
    setEndCheck(true);
    setCurrentTime(0);
  };

  const onClickToggle = () => {
    audioRef.current?.toggle();
  };

  const onClickForward = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.forward();
      setCurrentTime(audio.currentTime());
    }
  };

  const onClickRewind = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.rewind();
      setCurrentTime(audio.currentTime());
    }
  };

  const onChangeTime = (time: number) => {
    audioRef.current?.setTime(time);
    setCurrentTime(time);
  };

  const onChangeVolume = (volume: number) => {
    audioRef.current?.setVolume(volume);
  };

  const getDuration = () => {
    const audio = audioRef.current;
    return audio ? audio.duration() : 0;
  };

  return (
    audioPlayer && (
      <div className="grid grid-cols-[1fr_auto] items-start gap-4 md:grid-cols-[auto_1fr_auto] md:gap-16">
        <div className="hidden max-w-96 gap-1 md:grid">
          <p className="truncate text-14 font-medium" title={audioPlayer.name}>
            {audioPlayer.name}
          </p>
          <p className="truncate text-14" title={audioPlayer.description}>
            {audioPlayer.description}
          </p>
        </div>

        <div className="grid gap-4">
          <div className="flex justify-center gap-6">
            <Button variant="transparent" onClick={onClickRewind} icon="rewind" />
            <Button onClick={onClickToggle} icon={playing ? 'pause' : 'play'} />
            <Button variant="transparent" onClick={onClickForward} icon="forward" />
          </div>

          <AudioBar currentTime={currentTime} duration={getDuration()} onChange={onChangeTime} />
        </div>

        <div className="grid items-center gap-4 md:grid-cols-[140px_1fr]">
          <div className="max-md:hidden">
            <AudioVolume onChange={onChangeVolume} />
          </div>

          <Button onClick={() => setAudioPlayer(null)} icon="close" title="Close player" />
        </div>

        <Audio ref={audioRef} src={url} onUpdate={onUpdate} onAudioEnded={onAudioEnded} autoPlay />
      </div>
    )
  );
};
