import { secondsToMMSS } from '@/packages/utils/player';
import React, { useRef } from 'react';

type Props = {
  currentTime: number;
  duration: number;
  onChange: (time: number) => void;
};

export const AudioBar: React.FC<Props> = ({ currentTime, duration, onChange }) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const updatePosition = (clientX: number) => {
    const target = elementRef.current as HTMLElement;
    const x = clientX - target.getBoundingClientRect().x;
    const w = target.clientWidth;
    const newTime = duration * (x / w);
    onChange(newTime);
  };

  const onMouseMove = (event: MouseEvent) => {
    updatePosition(event.clientX);
  };

  const onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    elementRef.current?.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    updatePosition(event.clientX);
  };

  const onMouseUp = (event: MouseEvent) => {
    elementRef.current?.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  return (
    <div className="grid grid-cols-[auto_1fr_auto] items-center gap-4">
      <p className="min-w-12">{secondsToMMSS(currentTime)}</p>
      <div ref={elementRef} className="cursor-pointer py-3" onMouseDown={onMouseDown}>
        <div className="relative h-1 w-full rounded bg-blue-200/10">
          <div className="pointer-events-none absolute left-0 top-0 h-1 rounded bg-blue-200" style={{ width: `${(currentTime / duration) * 100}%` }}>
            <span className="absolute right-0 top-1/2 size-4 -translate-y-1/2 translate-x-1/2 rounded-full border border-white/50 bg-blue-200 shadow-lg" />
          </div>
        </div>
      </div>
      <p>{secondsToMMSS(duration)}</p>
    </div>
  );
};
