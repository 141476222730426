'use client';

import { useFloatingUIStore } from '@/packages/ui/floatingUI.store';
import { motion } from 'framer-motion';
import React from 'react';

type TooltipProps = {
  trigger: React.ReactNode;
  content: React.ReactNode;
  disabled?: boolean;
};

export const Tooltip: React.FC<TooltipProps> = ({ trigger, content, disabled }) => {
  const { setTooltip } = useFloatingUIStore();

  const onHover = (event: React.MouseEvent) => {
    const target = event.currentTarget as HTMLElement;
    const bounds = target.getBoundingClientRect();

    setTooltip({
      x: bounds.x + bounds.width / 2,
      y: bounds.y + window.scrollY,
      content,
    });
  };

  const onLeave = () => {
    setTooltip(null);
  };

  return (
    <div onPointerEnter={disabled ? undefined : onHover} onPointerLeave={disabled ? undefined : onLeave}>
      {trigger}
    </div>
  );
};

export type TooltipContentProps = {
  x: number;
  y: number;
  content: React.ReactNode;
};

export const TooltipContent: React.FC<TooltipContentProps> = ({ x, y, content }) => (
  <motion.div
    initial={{ opacity: 0, x: '-50%', y: '-90%' }}
    animate={{ opacity: 1, x: '-50%', y: '-100%' }}
    exit={{ opacity: 0, x: '-50%', y: '-90%' }}
    className="shadow-l pointer-events-none absolute z-px grid overflow-hidden rounded-lg border bg-white p-2"
    style={{ top: y - 4, left: x }}
  >
    {content}
  </motion.div>
);
