'use client';

import { Alert } from '@/packages/ui/Alert';
import { ContextMenuContent } from '@/packages/ui/ContextMenu';
import { Dialog } from '@/packages/ui/Dialog';
import { Notifications } from '@/packages/ui/Notification';
import { Spinner } from '@/packages/ui/Spinner';
import { TooltipContent } from '@/packages/ui/Tooltip';
import { AudioPlayer } from '@/packages/ui/player/AudioPlayer';
import { useFloatingUIStore } from '@/packages/ui/floatingUI.store';
import { AnimatePresence, motion } from 'framer-motion';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

export const FloatingUI = () => {
  const { alert, loading, tooltip, audioPlayer, notifications, contextMenu, dialog, loadingText, setAudioPlayer, setContextMenu, setTooltip } = useFloatingUIStore();
  const pathname = usePathname();

  useEffect(() => {
    setAudioPlayer(null);
    setContextMenu(null);
    setTooltip(null);
  }, [pathname, setAudioPlayer, setContextMenu, setTooltip]);

  return (
    <>
      <AnimatePresence>{tooltip && <TooltipContent x={tooltip.x} y={tooltip.y} content={tooltip.content} />}</AnimatePresence>

      <AnimatePresence>{notifications && <Notifications />}</AnimatePresence>

      <AnimatePresence>{contextMenu && <ContextMenuContent />}</AnimatePresence>

      {audioPlayer && (
        <div className="fixed bottom-0 left-0 z-40 w-full">
          <div className="ga-container">
            <div className="ga-container rounded-t-2xl bg-blue-400 p-10">
              <AudioPlayer />
            </div>
          </div>
        </div>
      )}

      <AnimatePresence>{alert && <Alert />}</AnimatePresence>
      <AnimatePresence>{dialog && <Dialog />}</AnimatePresence>

      <AnimatePresence>
        {loading && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="fixed inset-0 z-60 flex items-center justify-center bg-white">
            <div className="grid justify-items-center gap-2">
              <Spinner />
              {loadingText && <p className="text-center text-14">{loadingText}</p>}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
