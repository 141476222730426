import { useFloatingUIStore } from '@/packages/ui/floatingUI.store';
import { Button } from './Button';
import { AnimatePresence, motion } from 'framer-motion';

export type NotificationType = {
  title: string;
  uuid?: string;
  content: JSX.Element | JSX.Element[] | string;
  closeTime?: number;
};

export const Notifications = () => {
  const { notifications, removeNotification } = useFloatingUIStore();

  return (
    <div className="pointer-events-none fixed bottom-4 right-4 z-30 flex flex-col-reverse gap-4">
      <AnimatePresence>
        {notifications.map(notification => (
          <motion.div
            className="pointer-events-auto relative grid w-72 rounded-2xl bg-blue-400 shadow-lg lg:w-96"
            key={JSON.stringify(notification)}
            initial={{ opacity: 0, y: '10%' }}
            animate={{ opacity: 1, y: '0%' }}
            exit={{ opacity: 0, y: '10%' }}
          >
            <div className="grid grid-cols-[1fr_auto] items-center justify-between gap-4 p-4">
              <h2 className="break-all font-bold" title={notification.title}>
                {notification.title}
              </h2>
              <Button onClick={() => removeNotification(notification)} icon="close" />
            </div>

            <hr className="border-blue-200/10" />

            <div className="grid break-all p-4 text-14">{notification.content}</div>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};
