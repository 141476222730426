import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

export type OnUpdateParams = {
  playing: boolean;
};

type Props = {
  src: string;
  autoPlay?: boolean;
  forwardTime?: number;
  onUpdate?: ({ playing }: OnUpdateParams) => void;
  onAudioEnded?: () => void;
};

export type AudioHandle = {
  toggle: () => void;
  play: () => void;
  pause: () => void;
  forward: () => void;
  rewind: () => void;
  setTime: (time: number) => void;
  setVolume: (volume: number) => void;
  currentTime: () => number;
  duration: () => number;
  isPlaying: () => boolean;
};

export const Audio = React.forwardRef<AudioHandle, Props>(({ src, autoPlay, onUpdate, onAudioEnded, forwardTime = 5 }, forwardedRef) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (onUpdate) onUpdate({ playing });
  }, [playing]); // do not include onUpdate as dependency

  useImperativeHandle(
    forwardedRef,
    () => ({
      toggle: () => {
        const audio = audioRef.current;
        if (audio) {
          playing ? audio.pause() : audio.play();
        }
      },
      play: () => {
        const audio = audioRef.current;
        if (audio) audio.play();
      },
      pause: () => {
        const audio = audioRef.current;
        if (audio) audio.pause();
      },
      forward: () => {
        const audio = audioRef.current;
        if (audio) {
          audio.currentTime = audio.currentTime + forwardTime;
        }
      },
      rewind: () => {
        const audio = audioRef.current;
        if (audio) {
          audio.currentTime = audio.currentTime - forwardTime;
        }
      },
      duration: () => {
        const audio = audioRef.current;
        return audio ? audio.duration : 0;
      },
      currentTime: () => {
        const audio = audioRef.current;
        return audio ? audio.currentTime : 0;
      },
      setTime: (time: number) => {
        const audio = audioRef.current;
        if (audio) audio.currentTime = time;
      },
      setVolume: (volume: number) => {
        const audio = audioRef.current;
        if (audio) audio.volume = volume;
      },
      isPlaying: () => playing,
    }),
    [forwardTime, playing]
  );

  const onEnded = () => {
    setPlaying(false);
    if (onAudioEnded) onAudioEnded();
  };

  const onPlay = () => {
    setPlaying(true);
  };

  const onPause = () => {
    setPlaying(false);
  };

  return <audio ref={audioRef} src={src} className="hidden" onEnded={onEnded} onPlay={onPlay} onPause={onPause} autoPlay={autoPlay} preload="auto" />;
});

Audio.displayName = 'Audio';
