import { useFloatingUIStore } from '@/packages/ui/floatingUI.store';
import { Button } from './Button';

export type DialogType = {
  title?: string;
  content: React.ReactNode;
  cancelLabel?: string;
  confirmLabel?: string;
  maxWidth?: string;
  confirm: (event: React.MouseEvent) => void;
  cancel?: (event: React.MouseEvent) => void;
};

export const Dialog = () => {
  const { dialog, setDialog } = useFloatingUIStore();

  const onClickCancel = (event: React.MouseEvent) => {
    if (!dialog) return;

    setDialog(null);
    if (dialog.cancel) dialog.cancel(event);
  };

  if (dialog) {
    return (
      <div className="fixed inset-0 z-20 flex items-center justify-center" aria-roledescription="dialog">
        <div className="absolute inset-0 bg-black/50" onClick={onClickCancel} />

        <div className="relative grid w-full rounded-2xl bg-white" style={{ maxWidth: dialog.maxWidth || '500px' }}>
          <div className="flex items-center justify-between gap-2 p-6">
            <h3 className="text-22 font-medium">{dialog.title || 'Confirmation'}</h3>
            <Button
              onClick={(event: React.MouseEvent) => {
                setDialog(null);
                if (dialog.cancel) dialog.cancel(event);
              }}
              variant="transparent"
              icon="close"
            />
          </div>
          <hr />

          <div className="grid p-6">{dialog.content}</div>

          <div className="flex justify-end gap-4 bg-blue-50/5 p-6">
            <Button onClick={onClickCancel} label={dialog.cancelLabel || 'Cancel'} />

            <Button
              onClick={event => {
                setDialog(null);
                dialog.confirm(event);
              }}
              variant="primary"
              label={dialog.confirmLabel || 'Confirm'}
            />
          </div>
        </div>
      </div>
    );
  }

  return null;
};
